<template>
	<BreadCrumb />
	<el-tabs v-model="activeName" :tab-position="tabPosition" @tab-click="handleClick" style="height: 400px">
		<el-tab-pane label="所有工单" name="1">
			<div class="page">
				<el-table v-loading="loading" :data="TableData" fit stripe>
					<el-table-column :index="(ticketModule.page - 1) * pageSize + 1" label="序号" type="index" width="50" />
					<el-table-column label="工单编号" prop="issueTicketNum" width="140" show-overflow-tooltip />
					<el-table-column label="工单标题" prop="issueTicketTitle" width="100" />
					<el-table-column label="工单详细内容" prop="issueTicketContent" width="120" show-overflow-tooltip />
					<el-table-column label="工单状态" prop="issueTicketStatus" />
					<el-table-column label="工单优先级" prop="issueTicketPriority"/>
					<el-table-column label="问题主键" prop="qualityIssue.issueId" />
					<el-table-column label="问题名称" prop="qualityIssue.issueDesc" width="100" show-overflow-tooltip />
					<el-table-column label="作业名称" prop="qualityIssue.work.workName" width="100"  />
					<el-table-column label="任务主键" prop="qualityIssue.taskId" />
				</el-table>
				<el-pagination
					v-model:currentPage="ticketModule.page"
					:page-size="pageSize"
					:total="ticketModule.TicketTotal"
					background
					layout="total, prev, pager, next, jumper"
					@current-change="handleCurrentChange"
				/>
			</div>
		</el-tab-pane>

		<el-tab-pane label="未受理工单" name="2">
			<div class="page">
				<el-table v-loading="loading" :data="undealtTableData" fit stripe>
					<el-table-column :index="(ticketModule.undealtPage - 1) * pageSize + 1" label="序号" type="index" width="50" />
					<el-table-column label="工单编号" prop="issueTicketNum" width="140" show-overflow-tooltip />
					<el-table-column label="工单标题" prop="issueTicketTitle" width="100" />
					<el-table-column label="工单详细内容" prop="issueTicketContent" width="120" show-overflow-tooltip />
					<el-table-column label="工单状态" prop="issueTicketStatus"/>
					<el-table-column label="工单优先级" prop="issueTicketPriority"  />
					<el-table-column label="问题主键" prop="qualityIssue.issueId"  />
					<el-table-column label="问题名称" prop="qualityIssue.issueDesc" width="100" show-overflow-tooltip />
					<el-table-column label="作业名称" prop="qualityIssue.work.workName" width="100" show-overflow-tooltip />
					<el-table-column label="任务主键" prop="qualityIssue.taskId" />
					<el-table-column label="操作" fixed="right" width="80">
						<template #default="scope">
							<el-tooltip class="item" effect="light" content="指派人员处理工单" placement="top-end">
								<el-button type="primary" circle size="mini" @click="appointUserTicket(scope.row)">
									<el-icon :size="15"><i-avatar /></el-icon>
								</el-button>
							</el-tooltip>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination
					v-model:currentPage="ticketModule.undealtPage"
					:page-size="pageSize"
					:total="ticketModule.undealtTotal"
					background
					layout="total, prev, pager, next, jumper"
					@current-change="undealtPageCurrentChange"
				/>
			</div>
		</el-tab-pane>

		<el-tab-pane label="已受理工单" name="3">
			<div class="page">
				<el-table v-loading="loading" :data="acceptedTableData" fit stripe>
					<el-table-column :index="(ticketModule.acceptedPage - 1) * pageSize + 1" label="序号" type="index" width="50" />
					<el-table-column label="工单编号" prop="issueTicketNum" show-overflow-tooltip />
					<el-table-column label="工单标题" prop="issueTicketTitle" />
					<el-table-column label="工单详细内容" prop="issueTicketContent" show-overflow-tooltip />
					<el-table-column label="工单状态" prop="issueTicketStatus" />
					<el-table-column label="工单优先级" prop="issueTicketPriority" />
					<el-table-column label="问题主键" prop="qualityIssue.issueId"  />
					<el-table-column label="问题名称" prop="qualityIssue.issueDesc" width="100" show-overflow-tooltip />
					<el-table-column label="作业名称" prop="qualityIssue.work.workName" width="100" show-overflow-tooltip />
					<el-table-column label="任务主键" prop="qualityIssue.taskId"/>
					<el-table-column label="工单处理人" prop="handleBy" />
				</el-table>
				<el-pagination
					v-model:currentPage="ticketModule.acceptedPage"
					:page-size="pageSize"
					:total="ticketModule.acceptedTotal"
					background
					layout="total, prev, pager, next, jumper"
					@current-change="acceptedPageCurrentChange"
				/>
			</div>
		</el-tab-pane>
	</el-tabs>
	<el-dialog v-model="appointUserDate" :title="appointUserTitle">	
		<el-form ref="appoint" :model="appoint" :rules="appointUserRule">
			<el-form-item label="用户名:" prop="appointUser" >
				<el-select v-model="appoint.appointUser" placeholder="指派用户" style="width: 100%">
					<el-option v-for="item in appointData" :key="item.value" :label="item.label" :value="item.label" />
				</el-select>
			</el-form-item>
		</el-form>
		<template #footer>
			<span class="dialog-footer">
				<el-button type="primary" @click="updateTicket()">确 定</el-button>
        <el-button @click="appointUserDate = false">取 消</el-button>
			</span>
		</template>
	</el-dialog>
</template>

<script>
import BreadCrumb from '/src/components/BreadCrumb';
import { mapGetters } from 'vuex';
export default {
	name: 'ticketList',
	components: {
		BreadCrumb
	},
	data() {
		return {
			activeName: '1', //进入页面默认展示所有工单页面
			tabPosition: 'left',
			loading: true,
			TableData: '', //所有工单数据
			undealtTableData: '', //未受理的工单数据
			acceptedTableData: '', //已受理的工单数据
			appointUserDate: false, //指派弹出框的按钮
			appointUserTitle: '', //指派弹出框的标题
			appointData: [], //用户的集合
			userId: '',//用户id
			ticketId: 0,//工单id
			appoint: {
				appointUser: '' //用户
			},
			appointUserRule: {//指派用户验证
				appointUser: [
					{
						required: true,
						message: '指派人不可为空',
						trigger: 'blur'
					}
				]
			},
			ticketModule: {
				page: 1, //所有工单page
				TicketTotal: 0, //所有工单总数
				undealtPage: 1, //未受理工单page
				undealtTotal: 0, //未受理工单总数
				acceptedPage: 1, //已受理的工单page
				acceptedTotal: 0 //已受理的工单总数
			}
		};
	},
	computed: {
		...mapGetters(['pageSize', 'user'])
	},
	mounted: function() {
		const that = this;
		that.ticketList();
	},
	methods: {
		//所有工单数据
		ticketList() {
			const that = this;
			that.$axios
				.POST('/api/v1/core/issueTicket/findAllSubmittedTicketTotal', {
					page: that.ticketModule.page,
					size: that.pageSize
				})
				.then(res => {
					if (res.code == 200) {
						that.loading = false;
						that.TableData = res.data.list;
						that.ticketModule.TicketTotal = res.data.total;
						for (let item of that.TableData) {
							if (item.issueTicketStatus == 0) {
								item.issueTicketStatus = '尚未受理';
							} else if (item.issueTicketStatus == 1) {
								item.issueTicketStatus = '受理中';
							} else if (item.issueTicketStatus == 2) {
								item.issueTicketStatus = '已解决';
							} else if (item.issueTicketStatus == 3) {
								item.issueTicketStatus = '已关闭';
							}

							if (item.issueTicketPriority == 0) {
								item.issueTicketPriority = '低';
							} else if (item.issueTicketPriority == 1) {
								item.issueTicketPriority = '中 ';
							} else if (item.issueTicketPriority == 2) {
								item.issueTicketPriority = '高';
							} else if (item.issueTicketPriority == 3) {
								item.issueTicketPriority = '紧急';
							} else if (item.issueTicketPriority == 4) {
								item.issueTicketPriority = '非常紧急';
							}

							if (item.submitStatus == 0) {
								item.submitStatus = '未提交';
							} else if (item.submitStatus == 1) {
								item.submitStatus = '已提交';
							}
						}
					} else {
						this.$message({ showClose: true, message: '查询工单失败', type: 'error' });
					}
				});
		},
		//未受理工单数据
		undealtTableDataFun() {
			const that = this;
			that.$axios
				.POST('/api/v1/core/issueTicket/findAllUndealtTicket', {
					page: that.ticketModule.undealtPage,
					size: that.pageSize
				})
				.then(res => {
					if (res.code == 200) {
						that.loading = false;
						that.undealtTableData = res.data.list;
						that.ticketModule.undealtTotal = res.data.total;
						for (let item of that.undealtTableData) {
							if (item.issueTicketStatus == 0) {
								item.issueTicketStatus = '尚未受理';
							} else if (item.issueTicketStatus == 1) {
								item.issueTicketStatus = '受理中';
							} else if (item.issueTicketStatus == 2) {
								item.issueTicketStatus = '已解决';
							} else if (item.issueTicketStatus == 3) {
								item.issueTicketStatus = '已关闭';
							}

							if (item.issueTicketPriority == 0) {
								item.issueTicketPriority = '低';
							} else if (item.issueTicketPriority == 1) {
								item.issueTicketPriority = '中 ';
							} else if (item.issueTicketPriority == 2) {
								item.issueTicketPriority = '高';
							} else if (item.issueTicketPriority == 3) {
								item.issueTicketPriority = '紧急';
							} else if (item.issueTicketPriority == 4) {
								item.issueTicketPriority = '非常紧急';
							}

							if (item.submitStatus == 0) {
								item.submitStatus = '未提交';
							} else if (item.submitStatus == 1) {
								item.submitStatus = '已提交';
							}
						}
					} else {
						this.$message({ showClose: true, message: '查询工单失败', type: 'error' });
					}
				});
		},
		//已受理工单数据
		acceptedTableDataFun() {
			const that = this;
			that.$axios
				.POST('/api/v1/core/issueTicket/findAllAcceptedTicket', {
					page: that.ticketModule.acceptedPage,
					size: that.pageSize
				})
				.then(res => {
					if (res.code == 200) {
						that.loading = false;
						that.acceptedTableData = res.data.list;
						that.ticketModule.acceptedTotal = res.data.total;
						for (let item of that.acceptedTableData) {
							if (item.issueTicketStatus == 0) {
								item.issueTicketStatus = '尚未受理';
							} else if (item.issueTicketStatus == 1) {
								item.issueTicketStatus = '受理中';
							} else if (item.issueTicketStatus == 2) {
								item.issueTicketStatus = '已解决';
							} else if (item.issueTicketStatus == 3) {
								item.issueTicketStatus = '已关闭';
							}

							if (item.issueTicketPriority == 0) {
								item.issueTicketPriority = '低';
							} else if (item.issueTicketPriority == 1) {
								item.issueTicketPriority = '中 ';
							} else if (item.issueTicketPriority == 2) {
								item.issueTicketPriority = '高';
							} else if (item.issueTicketPriority == 3) {
								item.issueTicketPriority = '紧急';
							} else if (item.issueTicketPriority == 4) {
								item.issueTicketPriority = '非常紧急';
							}

							if (item.submitStatus == 0) {
								item.submitStatus = '未提交';
							} else if (item.submitStatus == 1) {
								item.submitStatus = '已提交';
							}
						}
					} else {
						this.$message({ showClose: true, message: '查询工单失败', type: 'error' });
					}
				});
		},
		//指派弹窗
		appointUserTicket(row) {
			const that = this;
			that.ticketId=row.id
			that.$axios
				.GET_SER('/api/v1/admin/user/byUsername', {
					username: row.createBy
				})
				.then(res => {
					if (res.code == 200) {
						that.$axios
							.GET_SER('/api/v1/admin/user/assignUsers', {
								ids: res.data.userId
							})
							.then(res => {
								if (res.code == 200) {
									this.appointData = [];
									$.each(res.data, (key, value) => {
										const dropDownFrame = { value: '', label: '' ,id: ''};
										dropDownFrame.value = key;
										dropDownFrame.label = value.username;
										this.appointData.push(dropDownFrame);
									});
								} else {
									this.$message({ showClose: true, message: '查询用户失败', type: 'error' });
								}
							});	
						that.appointUserDate = true;
						that.appointUserTitle = '请选择将此工单指派给谁';
					} else {
						this.$message({ showClose: true, message: '查询用户失败', type: 'error' });
					}
				});	
		},
		//指派后更新工单
		updateTicket(){
			const that = this;
			let handleBy = this.appoint.appointUser
			let datas = {
				id: that.ticketId,
				handleBy: handleBy
			};
			that.$axios.PUT('/api/v1/core/issueTicket/updateTicketAppointBy', datas).then(res => {
				if (res.code == 200) {
					that.appointUserDate = false;
					that.$message({
						type: 'success',
						message: '指派成功!'
					});
					that.undealtTableDataFun();
				} else if (res.code == 400) {
					that.$message({
						type: 'info',
						message: res.message
					});
				} else {
					that.$message({ showClose: true, message: '指派失败', type: 'error' });
				}
			});
		},
		//点击左侧进行切换页面
		handleClick(tab, event) {
			if (event.target.id == 'tab-1') {
				this.ticketList();
			} else if (event.target.id == 'tab-2') {
				this.undealtTableDataFun();
			} else if (event.target.id == 'tab-3') {
				this.acceptedTableDataFun();
			}
		},
		//所有工单分页点击
		handleCurrentChange(currentPage) {
			const that = this;
			console.log(currentPage);
			that.ticketModule.page = currentPage;
			that.ticketList();
		},
		//未受理工单分页点击
		undealtPageCurrentChange(currentPage) {
			const that = this;
			console.log(currentPage);
			that.ticketModule.undealtPage = currentPage;
			that.undealtTableDataFun();
		},
		//已受理工单分页点击
		acceptedPageCurrentChange(currentPage) {
			const that = this;
			console.log(currentPage);
			that.ticketModule.acceptedPage = currentPage;
			that.acceptedTableDataFun();
		}
	}
};
</script>

<style scoped></style>
